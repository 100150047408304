.twilio-breakout-room-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.twilio-breakout-room-container .left-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 320px;
  min-width: 320px;
  border-right: 2px solid #24313f;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left;
  transition: left 0.3s ease;
}

.twilio-breakout-room-container .left-panel.collapsed {
  left: -300px;
}

.twilio-breakout-room-container .main-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 320px;
  right: 0;
  bottom: 0;
  will-change: left;
  transition: left 0.3s ease;
}

.twilio-breakout-room-container .main-area.collapsed {
  left: 20px;
}

.twilio-breakout-room-container .screen-share-container {
  position: absolute;
  top: 0;
  left: 320px;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #181f26;
  z-index: 100;
  display: 'none';  
  padding: 10px 10px 10px 0;
}

.twilio-breakout-room-container .remote-videos-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
}
