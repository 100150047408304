/*
  Input Component Style
*/
.stacked-input {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.stacked-input input {
  background: transparent;
  color: #fff;
  border: 0;
  border-bottom: 1px solid #777;
  padding: 5px;
  text-align: left;
  outline: none;
  font-size: 14px;
  -webkit-transition: background 0.25s ease-in;
  -moz-transition: background 0.25s ease-in;
  -o-transition: background 0.25s ease-in;
  transition: background 0.25s ease-in;
}

.stacked-input label {
  color: #a9aaab;
  text-align: left;
}

.input-wrapper {
  margin: 10px;
}

.input-label {
  color: #a9aaab;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.input-label input {
  background: transparent;
  color: #fff;
  border: 0;
  border-bottom: 1px solid #777;
  padding: 5px;
  text-align: center;
  outline: none;
  font-size: 14px;
  margin-left: 20px;
  -webkit-transition: background 0.25s ease-in;
  -moz-transition: background 0.25s ease-in;
  -o-transition: background 0.25s ease-in;
  transition: background 0.25s ease-in;
}

.input-label input:focus,
.stacked-input input:focus {
  border-bottom: 1px solid #287737;
  background: rgba(40, 119, 55, 0.2);
}

.input-label.error input,
.stacked-input.error input {
  border-bottom: 1px solid #c6103a;
  background: rgba(198, 16, 58, 0.2);
}

.input-label.error .error-message,
.stacked-input.error .error-message {
  color: #c6103a;
  margin-top: 2px;
}

.input-label input[type='number']::-webkit-inner-spin-button,
.input-label input[type='number']::-webkit-outer-spin-button,
.stacked-input input[type='number']::-webkit-inner-spin-button,
.stacked-input input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
