.context-menu-container,
.context-menu-item-options {
  position: absolute;
  z-index: 9999;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
  background-color: #24313f;
  color: #c1c1c1;
  width: 200px;
  font-size: 14px;
}

.context-menu-item-options::-webkit-scrollbar {
  width: 6px;
}
.context-menu-item-options::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: transparent;
}
.context-menu-item-options::-webkit-scrollbar-thumb {
  background-color: #4e6379;
  outline: 1px solid #4e6379;
  border-radius: 3px;
}

.context-menu-container .context-menu-item {
  padding: 8px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.context-menu-container .context-menu-items-container > .context-menu-item:hover {
  color: green;
  background-color: #2b3745;
}

.context-menu-item-options > .context-menu-item:hover {
  color: green;
  background-color: #2b3745;
}

.context-menu-container .context-menu-item.danger,
.context-menu-item-options > .context-menu-item.danger {
  color: #c01533;
}

.context-menu-container .context-menu-item.danger:hover,
.context-menu-item-options > .context-menu-item.danger:hover {
  color: #c01533 !important;
}

.context-menu-container .context-menu-item.fixed:hover,
.context-menu-item-options > .context-menu-item.fixed:hover {
  color: #c1c1c1;
}

.context-menu-container .context-menu-item.disabled,
.context-menu-item-options > .context-menu-item.disabled {
  color: #606060;
  cursor: not-allowed;
}

.context-menu-container .context-menu-item.disabled:hover,
.context-menu-item-options > .context-menu-item.disabled:hover {
  color: #606060;
}
