.event-support-attendee-list,
.attendee-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.attendee-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  color: #f3f3f3;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  border-bottom: 2px solid rgb(30, 39, 47);
}

.attendee-list-item .name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 245px;
}

.attendee-list-item .status {
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attendee-list-item.highlighted-bold {
  font-weight: 900;
}

.event-support-attendee-list .list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #999999;
  border-bottom: 2px solid rgb(30, 39, 47);
}

.event-support-attendee-list .table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #f3f3f3;
  border-bottom: 2px solid rgb(30, 39, 47);
}

.event-support-attendee-list .table-row .column {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 4px;
  user-select: none;
  font-size: 13px;
}

.event-support-attendee-list .table-row.highlighted .column {
  font-weight: 600;
  font-style: italic;
}

.event-support-attendee-list .table-row.highlighted-bold .column {
  font-weight: 900;
  font-family: 'Open Sans';
}

.event-support-attendee-list .table-row .column .name {
  flex: 1;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.event-support-attendee-list .table-row .column .status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 30px;
}

.event-support-attendee-list .table-row .column .status img {
  margin-left: 2px;
}

.event-support-attendee-list .table-row .column:first-child {
  border-right: 1px solid rgb(30, 39, 47);
}

.event-support-attendee-list .table-row .column:last-child {
  border-left: 1px solid rgb(30, 39, 47);
}

.event-support-attendee-list .list-header .column {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  user-select: none;
  cursor: pointer;
}

.event-support-attendee-list .list-header .column span {
  font-size: 14px;
}

.event-support-attendee-list .list-header .column.active span {
  font-weight: 600;
}

.event-support-attendee-list .list-header .column:first-child {
  border-right: 1px solid rgb(30, 39, 47);
}

.event-support-attendee-list .list-header .column:last-child {
  border-left: 1px solid rgb(30, 39, 47);
}

.event-support-attendee-list .list-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #11171d;
  padding: 15px 10px;
  font-size: 12px;
}

.event-support-attendee-list .counter {
  display: flex;
  flex-direction: row;
}

.event-support-attendee-list .counter .label {
  color: #999999;
  font-weight: 600;
  margin-right: 5px;
}

.event-support-attendee-list .counter .value {
  color: #ffffff;
}
