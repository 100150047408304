/* Checkbox */

.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.checkbox img {
  width: 20px;
  margin-right: 10px;
}

.checkbox-label {
  color: rgba(184, 194, 204, 1);
  font-size: 14px;
}
