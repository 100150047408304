.button {
  position: relative;
  display: inline-block;
  padding: 7px 30px;
  border-radius: 2px;
  border: 0;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  user-select: none;
  margin: 0 5px;
}

.button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button .button-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button .button-content .icon {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.button .button-content .text {
  line-height: 26px;
  flex: 1;
}

.button.primary {
  background-color: #287737;
  color: #fff;
}

.button.secondary {
  background-color: #4e6379;
  color: #fff;
}

.button.danger {
  background-color: #711a20;
  color: #fff;
}

.button.link {
  background-color: transparent;
  color: #287737;
}

.button:active {
  position: relative;
  top: 2px;
  left: 2px;
}
