.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  /* border-radius: 4px; */
  outline: none;
  padding: 20px;
  background: rgba(24, 31, 38);

  width: 500px;
  height: 500px;
  z-index: 100;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
}

.paginated {
  color: white;
}

.pagination {
  display: flex;
  justify-content: center;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  font-size: 25px;
  padding: 10px;
}

ul.pagination li a {
  text-decoration: none;
  color: white;
  font-size: 12px;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: rgb(79, 100, 120);
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: white;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
