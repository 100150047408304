.connect-screen {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.connect-screen .sidebar {
  transition: margin 1s, width 1s, padding 1s;
  position: relative;
  left: 0;
  will-change: left;
  transition: left 0.3s ease;
}

.connect-screen .sidebar.collapsed {
  left: -300px;
}

.accept-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
}

.connect-screen .sidebar .webcam-container {
  width: 320px;
  height: 180px;
  margin-bottom: 10px;
  background-color: #1E2A37;
  position: relative;
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 320px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1E2A37;
}

.connect-screen .sidebar .webcam-container .pause-image {
  position: absolute;
  z-index: 1;
  width: 320px;
  height: 180px;
  align-items: center;
  justify-content: center;
  background-color: #1E2A37;
}

.connect-screen .sidebar .webcam-container .pause-image img {
  width: auto;
  height: 100%;
}

.connect-screen .sidebar .webcam-container video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.webcam-container.photo {
  display: flex;
  width: 320px;
  height: 180px;
  align-items: center;
  justify-content: center;
  background-color: #1E2A37;
}

.webcam-container.photo img {
  z-index: 1;
  width: 320px;
  height: 100%;
}

.connect-screen .sidebar .webcam-container .media-controls {
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

.connect-screen .sidebar .webcam-container .media-controls .media {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  align-items: center;
}

.connect-screen .sidebar form .form-controls .select-wrapper {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.connect-screen .sidebar form .form-controls .select-wrapper label {
  color: #a9aaab;
  text-align: left;
}

.connect-screen .sidebar form .actions {
  text-align: center;
}

.connect-screen .content {
  flex: 1;
  display: flex;
  color: #a9aaab;
  flex-direction: column;
  position: relative;
  border-left: 2px solid #1e272f;
  overflow-y: hidden;
}

.connect-screen .content.mobile-broadcast {
  border-right: 2px solid #1e272f;
  border-left: none;
}

.connect-screen .content.mobile-pando {
  border-left: 2px solid #1e272f;
  border-right: none;
}

.connect-screen .message {
  margin-bottom: 10px;
}

.connect-screen .controls {
  display: flex;
  margin-bottom: 10px;
}

.connect-screen .content .media {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 70px;
  left: 0;
  right: 0;
  padding: 25px;
}

.connect-screen .content .media video {
  width: 100%;
  height: 100%;
  background-color: #181f26;
}

.connect-screen .footer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.media-devices {
  display: flex;
  flex-direction: column;
  background-color: #1e272f;
  width: 320px;
}

.media-devices .device-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.media-devices .device-list-container .label {
  text-align: left;
  /* font-size: 13px; */
  color: #a9aaab;
}

.photo-modal-message {
  display: flex;
  flex-direction: row;
  background-color: #1e2a36;
  padding: 15px;
  margin: 35px 0 15px 0;
}

.photo-modal-message .text {
  color: white;
}

.photo-modal-message .text h4 {
  margin: 0;
  margin-bottom: 5px;
}

.photo-modal-message .text p {
  margin: 0;
  font-size: 13px;
}

.photo-modal-message img {
  width: 65px;
  margin: 0 30px;
}

.connect-screen .sidebar .webcam-container .status-label {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 45px;
  border-radius: 5px;
  text-align: center;
  right: 0;
  color: white;
  padding: 2px 7px;
  font-weight: 600;
  font-size: 12.5px;
  z-index: 100;
  user-select: none;
  cursor: default;
}
.connect-screen .sidebar .webcam-container .status-label.on-air {
  background: linear-gradient(90deg, #b4113c 0%, #ce0f25 100%);
}

.connect-screen .sidebar .webcam-container .status-label.on-wall {
  background: linear-gradient(90deg, #287737 0%, #2b923e 100%);
}

.connect-screen .sidebar .webcam-container .status-label.in-studio-queue {
  background: linear-gradient(90deg, #b7771e 0%, #e7992b 100%);
}

.status-label .tooltip {
  visibility: hidden;
  width: 260px;
  background-color: #1e272f;
  color: #a9aaab;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: -20px;
  right: -460%;
  margin-left: -110px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
  user-select: none;
}

.status-label.on-wall .tooltip {
  bottom: -20px;
  right: -370%;
}

.status-label.in-studio-queue .tooltip {
  bottom: -20px;
  right: -240%;
  width: 290px;
}

/* Tooltip arrow */
/* .on-air .tooltip::after,
.on-wall .tooltip::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #1e272f transparent transparent;
} */

/* Show the tooltip text when you mouse over the tooltip container */
.status-label:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.reaction-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 0 25px 0;
}

.reaction-button {
  background: #24313f;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  padding: 8px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.25s ease-in;
  width: 46px;
  height: 46px;
  position: relative;
}

.reaction-button img {
  position: relative;
}

.presenter-control {
  background: #24313f;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  margin: 0 10px;
}

.presenter-control button {
  background: #24313f;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.25s ease-in;
  width: 46px;
  height: 46px;
}

.presenter-control .label {
  color: #a9aaab;
  font-size: 14px;
}

.wall-stream-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #1e2a37;
}

.tabs .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 5px;
  cursor: pointer;
  position: relative;
}

.tab .tooltip.top,
.reaction-button .tooltip.top {
  visibility: hidden;
  width: 120px;
  background-color: #1D242F;
  color: #dedede;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 10;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
  user-select: none;
}

/* Tooltip arrow */
.tab .tooltip.top::after,
.reaction-button .tooltip.top::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1D242F transparent transparent transparent;
}

.tab .tooltip.bottom {
  visibility: hidden;
  width: 120px;
  background-color: #1D242F;
  color: #dedede;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 10;
  bottom: -60%;
  left: 50%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
  user-select: none;
}

/* Tooltip arrow */
.tab .tooltip.bottom::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #1e272f transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tab:hover .tooltip.top,
.reaction-button:hover .tooltip.top,
.tab:hover .tooltip.bottom {
  visibility: visible;
  opacity: 1;
}

.tabs .tab img {
  height: 45px;
}

.tab-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 55px;
}

.initials {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #888888;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 11px;
  line-height: 30px;
  margin-right: 10px;
  margin-left: 8px;
}

.initials.small {
  width: 25px;
  min-width: 25px;
  height: 25px;
  line-height: 25px;
}

.off-wall-stream {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 25px;
  height: 100%;
  position: relative;
}

.off-wall-stream .off-wall-stream-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.off-wall-stream .off-wall-stream-player-container {
  display: flex;
  padding: 25px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.off-wall-stream .message {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
}

.video-container .fullscreen-button {
  opacity: 0;
  position: absolute;
  left: calc(50% - 33px);
  bottom: 15px;
  z-index: 10;
  transition: opacity 0.25s ease-in;
}

.video-container:hover .fullscreen-button {
  opacity: 0.6;
}
