.media-devices {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.media-devices .device-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.media-devices .device-list-container .label {
  text-align: left;
  /* font-size: 13px; */
  color: #a9aaab;
}
