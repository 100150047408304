.login-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-message-container .message-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  text-align: center;
  font-size: 13px;
  color: #999;
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  color: #fff;
}

.landing-page .flex-columns {
  display: flex;
  flex-direction: row;
}

.landing-page .flex-columns .logo-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 60px;
}

.landing-page .flex-columns .content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.landing-page .flex-columns .content h1 {
  font-size: 45px;
  margin-bottom: 10px;
}

.landing-page .flex-columns .content h3 {
  width: 320px;
  text-transform: uppercase;
}

.landing-page .flex-columns .content p {
  width: 480px;
}

.landing-page .flex-columns .content .button-container {
  margin-top: 30px;
}