.event-support-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #11171d;
  padding: 15px 10px;
  font-size: 12px;
}

.event-support-header .total-users {
  display: flex;
  padding: 5px;
  color: white;
}

.event-support-header .total-users span {
  float: right;
  padding-right: 7px;
}

.event-support-header button {
  font-weight: normal;
  line-height: 1.42857143;
  font-size: 12px;
  padding: 2px 12px;
}

.message-history {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.message-history::-webkit-scrollbar {
  width: 6px;
}
.message-history::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: transparent;
}
.message-history::-webkit-scrollbar-thumb {
  background-color: #4e6379;
  outline: 1px solid #4e6379;
  border-radius: 3px;
}

.chat-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px 4px 0px;
  font-family: 'Open Sans';
}

.chat-message .container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 10px;
}

.chat-message .container .initials {
  width: 25px;
  min-width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  background: #888888;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 11px;
  margin-right: 10px;
  margin-top: 5px;
}

.chat-message .container .message-info {
  color: rgba(153, 153, 153, 0.7);
  margin-bottom: 1px;
  font-size: 11px;
  cursor: default;
}

.chat-message .container .message-text {
  color: white;
  font-weight: 300;
  font-size: 12px;
  min-height: 18px;
}

.chat-message .container .message-info small {
  font-size: 85%;
}

.chat-message .container .message-text .check-mark {
  border: 0.5px solid #3abb79;
  border-radius: 50%;
  display: none;
  cursor: pointer;
  position: relative;
  height: 12px;
  width: 12px;
  top: 3px;
}

.chat-message .container .message-text .check-mark.visible {
  display: inline-block;
  cursor: default;
}

.chat-message .container .message-text:hover .check-mark {
  display: inline-block;
}

.chat-message .container .message-text .check-mark .tooltip.top {
  visibility: hidden;
  width: 100px;
  background-color: #1d242f;
  color: #dedede;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 10;
  bottom: 130%;
  left: 115%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 11px;
  user-select: none;
}

/* Tooltip arrow */
.chat-message .container .message-text .check-mark .tooltip.top::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1d242f transparent transparent transparent;
}

.chat-message .container .message-text .check-mark:hover .tooltip.top {
  visibility: visible;
  opacity: 1;
}

.chat-input-container .chat-tabs {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  height: 41px;
  overflow: hidden;
}

.chat-input-container .more-tab {
  border: 0;
  background-color: #11171d;
  border-radius: 15px;
  font-family: 'Open Sans';
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  padding: 4px 8px;
  cursor: pointer;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-input-container .more-tab span {
  margin-right: 8px;
}

.chat-input-container .more-tab:hover {
  background: #4d637a !important;
  color: white !important;
  border: 0 !important;
}

.chat-input-container .chat-tabs .tab {
  border: 0;
  background: #11171d;
  border-radius: 15px;
  font-family: 'Open Sans';
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  padding: 4px 8px;
  cursor: pointer;
  margin: 0;
  display: flex;
  flex-direction: row;
}

.chat-input-container .chat-tabs .tab:hover {
  background: #4d637a !important;
  color: white !important;
  border: 0 !important;
}

.chat-input-container .chat-tabs .tab.private {
  border: 0;
  background: #11171d;
  border-radius: 15px;
  font-family: 'Open Sans';
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  padding: 4px 8px;
  cursor: pointer;
  margin: 0;
  display: flex;
  flex-direction: row;
  margin-left: 5px;
}

.chat-input-container .chat-tabs .tab.private span {
  min-width: 65px;
  max-width: 65px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.chat-input-container .chat-tabs .tab.active,
.chat-input-container .chat-tabs .tab.private.active {
  background: #4d637a !important;
  color: white !important;
  border: 0 !important;
}

.chat-input {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #11171d;
  padding: 10px;
  height: auto;
}

.chat-input textarea {
  width: 100%;
  background: #11171d;
  border: 0;
  outline: none;
  color: rgba(255, 255, 255, 0.7);
  padding: 5px;
  margin-right: 10px;
  resize: none;
  height: 50px;
  overflow: hidden;
  font-family: 'Open Sans';
  font-size: 12px;
}

.chat-input button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: #277635;
  color: white;
  outline: none !important;
  box-shadow: none !important;
}

.chat-input button:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.chat-input .to-all {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 12px;
  width: 55px;
}

.chat-input .to-all input {
  margin: 0;
}

.chat-input .to-all label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0;
  margin-left: 5px;
  font-weight: 300;
}

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  width: 360px !important;
}

@media screen and (orientation:portrait) and (min-width:320px) {
  .Toastify__toast-container {
    width: 100% !important;
  }
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
  background: #1e2a37 !important;
}
.Toastify__toast--rtl {
}
.Toastify__toast-body {
  background: #1e2a37;
}

/** Used to position the icon **/
.Toastify__toast-icon {
}

/** handle the notificaiton color and the text color based on the theme **/
.Toastify__toast-theme--dark {
  background: #1e2a37;
}
.Toastify__toast-theme--light {
  background: #1e2a37;
}
.Toastify__toast-theme--colored.Toastify__toast--default {
}
.Toastify__toast-theme--colored.Toastify__toast--info {
}
.Toastify__toast-theme--colored.Toastify__toast--success {
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
}
.Toastify__toast-theme--colored.Toastify__toast--error {
}

.Toastify__progress-bar {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
}
.Toastify__progress-bar-theme--dark {
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
}
/** colored notifications share the same progress bar color **/
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  color: white !important;
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

.label_private_chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #11171d;
  padding: 10px;
}
.label_private_chat .initials {
  width: 30px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background: #888888;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 11px;
  margin-right: 10px;
  margin-top: 5px;
}

.label_private_chat .text {
  flex: 1;
  font-size: 12px;
  color: #999999;
}

.label_private_chat .inactive_user {
  color: white;
  display: inline-block;
  background-color: #711a20;
  border: none;
  font-size: 12px;
  font-weight: 300;
}

.highlight {
  animation: highlight 2s infinite;
}

@keyframes highlight {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.33;
  }
  100% {
    opacity: 1;
  }
}

.offline-label {
  color: white;
  background-color: #711a20;
  border: none;
  font-size: 12px;
  font-weight: 300;
  padding: 0px 4px;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin-left: 5px;
}
