.venue-login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
}

.venue-login form {
  width: 90%;
}
