.janus-room-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.janus-room-container .sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 320px;
  width: 320px;
  min-width: 320px;
  border-right: 2px solid #1e272f;
  -webkit-transition: left 0.25s ease-out;
  -moz-transition: left 0.25s ease-out;
  -o-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
  will-change: left;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.sidebar-container {
  flex: 1;
  position: relative;
}

.janus-room-container .sidebar.collapsed {
  left: -300px;
  -webkit-transition: left 0.25s ease-in;
  -moz-transition: left 0.25s ease-in;
  -o-transition: left 0.25s ease-in;
  transition: left 0.25s ease-in;
  will-change: left;
}

.janus-room-container .sidebar.collapsed .room-header {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  will-change: opacity;
}

.janus-room-container .sidebar.collapsed .participant-list {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  will-change: opacity;
}

.janus-room-container .sidebar.collapsed .local-media {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  will-change: opacity;
}

.janus-room-container .sidebar.collapsed .chat {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  will-change: opacity;
}

.janus-room-container .sidebar .local-media {
  width: 320px;
  height: 240px;
  position: relative;
  background-color: black;
  position: relative;
}

.janus-room-container .sidebar .local-media .photo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.janus-room-container .sidebar .local-media .photo img {
  width: 320px;
  max-width: 320px;
  height: auto;
}

.janus-room-container .sidebar .local-media .local-participant-name {
  color: white;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  right: 55px;
  padding: 10px;
  text-shadow: 1px 1px #000000;
  z-index: 2;
  cursor: default;
}

.janus-room-container .sidebar .local-media video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  will-change: opacity;
}

.janus-room-container .sidebar .participant-list {
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 2px solid #1c242d;
  width: 320px;
  min-width: 320px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  will-change: opacity;
  opacity: 1;
}

.janus-room-container .sidebar .participant-list .room-participant {
  display: flex;
  color: #a9aaab;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #1c242d;
  padding: 15px;
  user-select: none;
}

.janus-room-container .room-header {
  display: flex;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
  max-height: 40px;
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  will-change: opacity;
  opacity: 1;
}

.janus-room-container .room-header div {
  margin: 0 10px;
}

.janus-room-container .remote-media-container {
  flex: 1 1;
  display: flex;
  position: absolute;
  left: 320px;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: left 0.25s ease-in;
  -moz-transition: left 0.25s ease-in;
  -o-transition: left 0.25s ease-in;
  transition: left 0.25s ease-in;
  will-change: left;
}

.janus-room-container .remote-media-container.collapsed {
  left: 20px;
  -webkit-transition: left 0.25s ease-out;
  -moz-transition: left 0.25s ease-out;
  -o-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
  will-change: left;
}

.janus-room-container .remote-media-container .participants-grid {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.janus-room-container .remote-media-container .participants-grid .grid-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.janus-room-container .remote-media-container .presentation-area {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.janus-room-container .remote-media-container .screen-track {
  width: 100%;
  height: 100%;
  position: relative;
}

.janus-room-container .remote-media-container .screen-track video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.janus-room-container .remote-media-container .remote-media {
  width: 320px;
  height: 240px;
  position: relative;
  background-color: black;
  margin: 15px;
  user-select: none;
}

.janus-room-container .remote-media-container .remote-media video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
}

.janus-room-container .webcam-preview {
  width: 320px;
  height: 240px;
  position: relative;
  background-color: black;
}

.janus-room-container .webcam-preview .name {
  color: white;
  font-size: 0.5em;
  font-weight: 600;
  position: absolute;
  bottom: 0.3em;
  left: 0.2em;
  right: 0;
  padding: 0.25em 0.5em;
  text-shadow: 1px 1px 5px #222;
  z-index: 2;
  cursor: default;
}

.janus-room-container .webcam-preview .settings {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;
}

.janus-room-container .webcam-preview video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.janus-room-container .webcam-preview .photo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.janus-room-container .webcam-preview .photo img {
  width: 100%;
  height: auto;
}

.janus-room-container .media-controls {
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 36px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

.janus-room-container .media-controls .media {
  display: flex;
  flex: 1;
  justify-content: center;
  align-self: space-between;
}

.text-button {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 600;
  user-select: none;
}

.icon-button {
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 6px 16px;
  cursor: pointer;
  margin: 2px 0;
  justify-content: center;
  align-items: center;
  outline: none;
}

.icon-button .tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #1e272f;
  color: #a9aaab;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
  user-select: none;
}

/* Tooltip arrow */
.icon-button .tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1e272f transparent transparent transparent;
}

@media (hover: hover) and (pointer: fine) {
  /* Show the tooltip text when you mouse over the tooltip container */
  .icon-button:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}

.collapse-button {
  position: absolute;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
  border-radius: 50%;
  background-color: #eee;
  box-shadow: 0px 0px 7px 1px #0a081259;
  top: 18px;
  left: -15px;
  font-size: 15px;
  border: 2px solid #ddd;
}

.collapse-button:hover {
  background-color: #eee;
}

.settings {
  display: flex;
  flex-direction: column;
  background-color: #181f26;
  width: 320px;
  border: 2px solid #1e272f;
  box-shadow: 0px 0px 7px 1px #0a081259;
  padding: 10px 10px 15px 10px;
  margin-top: 0;
}

.settings .device-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.settings .device-list-container .label {
  text-align: left;
  /* font-size: 13px; */
  color: #a9aaab;
}

.pulse {
  background-color: rgba(183, 19, 48, 0.7);
  animation: pulse 4s infinite;
}

@keyframes pulse {
  0% {
    background-color: rgba(183, 19, 48, 0);
  }

  60% {
    background-color: rgba(183, 19, 48, 0.7);
  }

  100% {
    background-color: rgba(183, 19, 48, 0);
  }
}
