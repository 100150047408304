.poll-container {
  position: absolute;
  top: 180px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1e2a36;
  padding: 10px 15px;
  color: #ffffffef;
  display: flex;
  flex-direction: column;
  z-index: 11;
}
