.speed-test-container {
  background-color: #1e2a38;
  color: #f3f3f3;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  height: auto;
  width: 400px;
}

.speed-test-container .header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: normal;
  font-size: 18px;
  color: #f3f3f3;
  padding: 20px 15px 0px;
}

.speed-test-container .header .name {
  font-weight: bold;
}

.speed-test-container .status {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  font-size: 12px;
  border-radius: 10px;
  letter-spacing: 1px;
}

.speed-test-container .status .indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.speed-test-container .status span {
  font-weight: bold;
}

.speed-test-container .server-type {
  padding: 0px 15px;
}

.speed-test-container .server-type > img {
  margin: 15px;
  margin-top: 5px;
  width: 70%;
}

.speed-test-container .instance-id {
  font-size: 14px;
  color: #43586f;
  margin-bottom: 5px;
}

.speed-test-container .instance-type {
  font-size: 14px;
  color: #43586f;
  margin-bottom: 15px;
  display: inline-block;
  height: 22px;
  border-bottom: 1px solid transparent;
}

.speed-test-container .instance-type.stopped:hover {
  border-bottom: 1px solid #43586f;
  cursor: pointer;
}

.speed-test-container .instance-type .edit-capacity button {
  background: transparent;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
  user-select: none;
  opacity: 0.7;
}

.speed-test-container .instance-type .edit-capacity button img {
  width: 15px;
  height: 15px;
  margin: 0;
}

.speed-test-container .uptime {
  font-size: 15px;
  color: #43586f;
  height: 20px;
  margin-bottom: 5px;
}

.speed-test-container .monitoring {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 5px 0 5px;
}

.speed-test-container .actions {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.widget {
  padding: 5px 15px 15px 15px;
}

.widget .title {
  color: #8b8b8b;
  font-size: 13px;
  margin-bottom: 6px;
}

.widget .control .progress-container {
}

.widget .control .progress-container .labels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.widget .control .progress-container .labels .percentage {
  color: #69b846;
}

.widget .control .progress-container .progress-bar {
  background: rgb(105, 184, 70);
  /* background: linear-gradient(90deg, rgba(105, 184, 70, 1) 0%, rgba(185, 183, 17, 1) 50%, rgba(214, 0, 0, 1) 100%); */
  width: 100%;
  overflow: hidden;
  border: 0;
}

.widget .control .progress-container .progress-bar .progress {
  position: relative;
  width: 100%;
  left: 0%;
  height: 5px;
  background-color: #293746;
  /* transition: left 0.2s ease;
  -webkit-transition: left 0.2s ease;
  -moz-transition: left 0.2s ease;
  -o-transition: left 0.2s ease;
  transition: left 0.2s ease; */
  border: 0;
  margin: 0;
}

.widget .control .network-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.widget .control .network-container .icon-label {
  display: flex;
  align-items: center;
  justify-content: center;
}
